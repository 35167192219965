import causaAPI from "apis/causa";

export async function getProducts() {
  const response = await causaAPI.get("/products");
  return response.data;
}

export async function createProduct(data) {
  const response = await causaAPI.post("/product", data);
  return response.data;
}

export async function editProduct(id, data) {
  const response = await causaAPI.put(`/product/${id}`, data);
  return response.data;
}

export async function deleteProduct(id) {
  const response = await causaAPI.delete(`/product/${id}`);
  return response.data;
}
