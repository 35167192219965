import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useTranslation } from "react-i18next";
import useAdmin from "hooks/useAdmin";
import { RECEIPT_DELIVERED, RECEIPT_PENDING } from "constants/receipts";
import formatDate from "utils/formatDate";
import formatPrice from "utils/formatPrice";

function getClientName(clients, id) {
  const client = clients.find((client) => client.id === id);
  if (client) {
    return `${client.company}`;
  } else {
    return "Desconocido";
  }
}

function getReceiptStatus(status) {
  switch (status) {
    case RECEIPT_PENDING:
      return "Pendiente";
    case RECEIPT_DELIVERED:
      return "Entregado";
    default:
      return "Desconocido";
  }
}

export default function ReceiptsTable({
  receipts,
  handleClickSee,
  handleClickEdit,
  handleClickDelete,
}) {
  const {
    state: { clients },
  } = useAdmin();
  const { t } = useTranslation();
  const tpath = "pages.admin.receipts.table";

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="Tabla de remitos">
        <TableHead>
          <TableRow>
            <TableCell>{t(`${tpath}.headers.id`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.client`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.status`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.total_value`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.created_at`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.actions`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {receipts.map((receipt) => (
            <TableRow
              key={receipt.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{receipt.id}</TableCell>
              <TableCell>{getClientName(clients, receipt.clientId)}</TableCell>
              <TableCell>
                {receipt.invoiceId ? (
                  <a
                    style={{ display: "block" }}
                    key={receipt.id}
                    href={`/admin/recibos/${receipt.invoiceId}`}
                    target="__blank"
                  >
                    Pagado
                  </a>
                ) : (
                  getReceiptStatus(receipt.status)
                )}
              </TableCell>
              <TableCell>${formatPrice(receipt.totalValue)}</TableCell>
              <TableCell>{formatDate(receipt.createdAt)}</TableCell>
              <TableCell>
                <Box display="flex" gap={{ md: 1 }}>
                  <IconButton
                    onClick={() => handleClickSee(receipt)}
                    color="success"
                    aria-label={t(`${tpath}.actions.see`)}
                  >
                    <VisibilityRoundedIcon />
                  </IconButton>
                  {receipt.status === RECEIPT_PENDING && (
                    <>
                      <IconButton
                        onClick={() => handleClickEdit(receipt)}
                        color="info"
                        aria-label={t(`${tpath}.actions.edit`)}
                      >
                        <EditRoundedIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClickDelete(receipt)}
                        color="error"
                        aria-label={t(`${tpath}.actions.delete`)}
                      >
                        <DeleteRoundedIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
