import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import DeleteReceiptModal from "./DeleteReceiptModal";
import useModal from "hooks/useModal";
import useAdmin from "hooks/useAdmin";
import EditReceiptModal from "./EditReceiptModal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import CreateReceiptModal from "./CreateReceiptModal";
import ReceiptsTable from "./ReceiptsTable";
import { useNavigate } from "react-router-dom";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

export default function ShowAllReceipts() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { clients, products, receipts },
    getClients,
    getProducts,
    getReceipts,
  } = useAdmin();
  const [openCreateModal, closeCreateModal, createModalState] = useModal();
  const [openDeleteModal, closeDeleteModal, deleteModalState] = useModal();
  const [openEditModal, closeEditModal, editModalState] = useModal(false, {
    client: {
      name: "",
      lastname: "",
      company: "",
      address: "",
      phone: "",
    },
  });

  const handleClickCreate = () => {
    openCreateModal();
  };

  const handleClickSee = (receipt) => {
    navigate(`/admin/remitos/${receipt.id}`);
  };

  const handleClickEdit = (receipt) => {
    openEditModal({ receipt });
  };

  const handleClickDelete = (receipt) => {
    openDeleteModal({ receipt });
  };

  useEffect(() => {
    if (!clients.length) {
      getClients();
    }
    if (!products.length) {
      getProducts();
    }
    if (!receipts.length) {
      getReceipts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CreateReceiptModal
        modalState={createModalState}
        closeModal={closeCreateModal}
      />
      <EditReceiptModal
        modalState={editModalState}
        closeModal={closeEditModal}
      />
      <DeleteReceiptModal
        modalState={deleteModalState}
        closeModal={closeDeleteModal}
      />
      <Grid p={{ xs: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} container>
        <Grid item xs={12}>
          <Paper>
            <Box
              p={{ xs: 2, md: 3 }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">
                {t("pages.admin.receipts.title")}
              </Typography>
              <Button onClick={handleClickCreate}>
                {t("pages.admin.receipts.buttons.create")}
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {receipts.length ? (
              <ReceiptsTable
                receipts={receipts}
                handleClickSee={handleClickSee}
                handleClickEdit={handleClickEdit}
                handleClickDelete={handleClickDelete}
              />
            ) : (
              <Box p={{ xs: 2, md: 3 }} display="flex" alignItems="center">
                <WarningAmberRoundedIcon sx={{ mr: 1 }} />
                <Typography variant="body2">
                  {t("pages.admin.receipts.no_receipts")}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
