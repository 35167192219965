import CreateDataContext from './CreateDataContext';

const alertsReducer = (state, action) => {
  switch (action.type) {
    case 'setSnackbarState':
      return {
        ...state,
        snackbarState: action.payload,
      };
    case 'updateSnackbarState':
      return {
        ...state,
        snackbarState: { ...state.snackbarState, ...action.payload },
      };
    default:
      return state;
  }
};

const openSnackbar = (dispatch) => (snackbarState) => {
  dispatch({
    type: 'setSnackbarState',
    payload: {
      state: true,
      ...snackbarState,
    },
  });
};

const closeSnackbar = (dispatch) => (snackbarState) => {
  dispatch({
    type: 'updateSnackbarState',
    payload: {
      state: false,
      ...snackbarState,
    },
  });
};

export const { Context, Provider } = CreateDataContext(
  alertsReducer,
  {
    openSnackbar,
    closeSnackbar,
  },
  {
    snackbarState: {
      state: false,
      severity: 'success',
      message: '',
      icon: null,
      autoHideDuration: 3000,
      button: {
        label: '',
        onClick: () => {},
      },
    },
  },
);
