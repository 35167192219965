import {
  getClients as getClientsAPI,
  createClient as createClientAPI,
  editClient as editClientAPI,
  deleteClient as deleteClientAPI,
} from "services/admin/clients";
import {
  getProducts as getProductsAPI,
  createProduct as createProductAPI,
  editProduct as editProductAPI,
  deleteProduct as deleteProductAPI,
} from "services/admin/products";
import {
  getReceipts as getReceiptsAPI,
  createReceipt as createReceiptAPI,
  editReceipt as editReceiptAPI,
  deleteReceipt as deleteReceiptAPI,
} from "services/admin/receipts";
import {
  getInvoices as getInvoicesAPI,
  createInvoice as createInvoiceAPI,
  editInvoice as editInvoiceAPI,
  deleteInvoice as deleteInvoiceAPI,
} from "services/admin/invoices";
import CreateDataContext from "./CreateDataContext";

const authReducer = (state, action) => {
  switch (action.type) {
    case "setClients":
      return { ...state, clients: action.payload };
    case "createClient":
      return { ...state, clients: [...state.clients, action.payload] };
    case "editClient":
      return {
        ...state,
        clients: state.clients.map((client) =>
          client.id === action.payload.id
            ? { ...client, ...action.payload.data }
            : client
        ),
      };
    case "deleteClient":
      return {
        ...state,
        clients: state.clients.filter((client) => client.id !== action.payload),
      };
    case "setProducts":
      return { ...state, products: action.payload };
    case "createProduct":
      return { ...state, products: [...state.products, action.payload] };
    case "editProduct":
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.id
            ? { ...product, ...action.payload.data }
            : product
        ),
      };
    case "deleteProduct":
      return {
        ...state,
        products: state.products.filter(
          (product) => product.id !== action.payload
        ),
      };
    case "setReceipts":
      return { ...state, receipts: action.payload };
    case "createReceipt":
      return { ...state, receipts: [...state.receipts, action.payload] };
    case "editReceipt":
      return {
        ...state,
        receipts: state.receipts.map((receipt) =>
          receipt.id === action.payload.id
            ? { ...receipt, ...action.payload.data }
            : receipt
        ),
      };
    case "deleteReceipt":
      return {
        ...state,
        receipts: state.receipts.filter(
          (receipt) => receipt.id !== action.payload
        ),
      };
    case "setInvoices":
      return { ...state, invoices: action.payload };
    case "createInvoice":
      return { ...state, invoices: [...state.invoices, action.payload] };
    case "editInvoice":
      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice.id === action.payload.id
            ? { ...invoice, ...action.payload.data }
            : invoice
        ),
      };
    case "deleteInvoice":
      return {
        ...state,
        invoices: state.invoices.filter(
          (invoice) => invoice.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

const getClients = (dispatch) => () => {
  getClientsAPI().then((res) => {
    dispatch({ type: "setClients", payload: res.data });
  });
};

const createClient = (dispatch) => (data) => {
  createClientAPI(data).then((res) => {
    dispatch({ type: "createClient", payload: res.data });
  });
};

const editClient = (dispatch) => (id, data) => {
  editClientAPI(id, data).then(() => {
    dispatch({ type: "editClient", payload: { id, data } });
  });
};

const deleteClient = (dispatch) => (id) => {
  deleteClientAPI(id).then(() => {
    dispatch({ type: "deleteClient", payload: id });
  });
};

const getProducts = (dispatch) => () => {
  getProductsAPI().then((res) => {
    dispatch({ type: "setProducts", payload: res.data });
  });
};

const createProduct = (dispatch) => (data) => {
  createProductAPI(data).then((res) => {
    dispatch({ type: "createProduct", payload: res.data });
  });
};

const editProduct = (dispatch) => (id, data) => {
  editProductAPI(id, data).then(() => {
    dispatch({ type: "editProduct", payload: { id, data } });
  });
};

const deleteProduct = (dispatch) => (id) => {
  deleteProductAPI(id).then(() => {
    dispatch({ type: "deleteProduct", payload: id });
  });
};

const getReceipts = (dispatch) => () => {
  getReceiptsAPI().then((res) => {
    dispatch({ type: "setReceipts", payload: res.data });
  });
};

const createReceipt = (dispatch) => (data) => {
  createReceiptAPI(data).then((res) => {
    res.data.totalValue = data.products.reduce((accumulator, product) => {
      const productTotal = product.value * product.quantity;
      return accumulator + productTotal;
    }, 0)
    dispatch({ type: "createReceipt", payload: res.data });
  });
};

const editReceipt = (dispatch) => (id, data) => {
  editReceiptAPI(id, data).then(() => {
    dispatch({ type: "editReceipt", payload: { id, data } });
  });
};

const deleteReceipt = (dispatch) => (id) => {
  deleteReceiptAPI(id).then(() => {
    dispatch({ type: "deleteReceipt", payload: id });
  });
};

const getInvoices = (dispatch) => () => {
  getInvoicesAPI().then((res) => {
    dispatch({ type: "setInvoices", payload: res.data });
  });
};

const createInvoice = (dispatch) => (data) => {
  const dataToSend = { ...data };
  dataToSend.receipts = dataToSend.receipts.map((receipt) => receipt.id);

  createInvoiceAPI(dataToSend).then((res) => {
    res.data.Receipts = data.receipts;
    res.data.Administrator = {
      id: res.data.administratorId,
      username: "Tu mismo hace un momento",
    };

    dispatch({ type: "createInvoice", payload: res.data });
  });
};

const editInvoice = (dispatch) => (id, data) => {
  editInvoiceAPI(id, data).then(() => {
    dispatch({ type: "editInvoice", payload: { id, data } });
  });
};

const deleteInvoice = (dispatch) => (id) => {
  deleteInvoiceAPI(id).then(() => {
    dispatch({ type: "deleteInvoice", payload: id });
  });
};

export const { Context, Provider } = CreateDataContext(
  authReducer,
  {
    getClients,
    createClient,
    editClient,
    deleteClient,

    getProducts,
    createProduct,
    editProduct,
    deleteProduct,

    getReceipts,
    createReceipt,
    editReceipt,
    deleteReceipt,

    getInvoices,
    createInvoice,
    editInvoice,
    deleteInvoice,
  },
  {
    clients: [],
    products: [],
    receipts: [],
    invoices: [],
  }
);
