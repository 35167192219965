import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getInvoice } from "services/client/invoices";
import formatDate from "utils/formatDate";
import ReceiptsTable from "./ReceiptsTable";
import formatPrice from "utils/formatPrice";
import { useNavigate } from "react-router-dom";

export default function Invoice({ id }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState({});

  useEffect(() => {
    getInvoice(id)
      .then((res) => {
        setInvoice(res.data);
      })
      .catch((err) => {
        navigate("/client/recibos");
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Grid p={{ xs: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} container>
      <Grid item xs={12}>
        <Paper>
          <Typography p={{ xs: 2, md: 3 }} fontWeight="bold">
            {t("pages.client.invoice.title", { id: invoice.id })}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ height: "100%" }}>
          <Box p={{ xs: 2, md: 3 }}>
            <Grid spacing={{ xs: 1, md: 2 }} container>
              <Grid item xs={12}>
                <Typography mb={1} fontWeight="bold">
                  {t("pages.client.invoice.data.title")}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gap={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.invoice.data.observation")}:
                  </Typography>
                  <Typography variant="body2">
                    {invoice.observation
                      ? invoice.observation
                      : t("pages.client.invoice.data.no_observation")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gap={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.invoice.data.created_at")}:
                  </Typography>
                  <Typography variant="body2">
                    {invoice.createdAt && formatDate(invoice.createdAt)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gap={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.invoice.data.created_by")}:
                  </Typography>
                  <Typography variant="body2">
                    {invoice.Administrator?.username}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <Box p={{ xs: 2, md: 3 }}>
            <Grid spacing={{ xs: 1, md: 2 }} container>
              <Grid item xs={12}>
                <Typography mb={1} fontWeight="bold">
                  {t("pages.client.invoice.receipts.title")}
                </Typography>
                <Divider />
              </Grid>
              {invoice.Receipts && (
                <Grid item xs={12}>
                  <ReceiptsTable receipts={invoice.Receipts} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <Box p={{ xs: 2, md: 3 }}>
            <Grid spacing={{ xs: 1, md: 2 }} container>
              <Grid item xs={12}>
                <Typography mb={1} fontWeight="bold">
                  {t("pages.client.invoice.summary.title")}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.invoice.summary.receipts", {
                      quantity: invoice.Receipts?.length,
                    })}
                  </Typography>
                  <Typography variant="body2">
                    $
                    {invoice.Receipts &&
                      formatPrice(
                        invoice.Receipts.reduce(
                          (accumulator, receipt) =>
                            accumulator + receipt.totalValue,
                          0
                        )
                      )}
                  </Typography>
                </Box>
              </Grid>
              {invoice.Receipts && (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2" fontWeight="bold">
                      {t("pages.client.invoice.summary.total")}
                    </Typography>
                    <Typography variant="body2">
                      $
                      {formatPrice(
                        invoice.Receipts.reduce(
                          (accumulator, receipt) =>
                            accumulator + receipt.totalValue,
                          0
                        )
                      )}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
