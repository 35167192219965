import { Grid } from "@mui/material";
import LineChartExample from "./components/Charts/LineChart";

export default function HomePage() {
  return (
    <Grid p={{ xs: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} container>
      <Grid item xs={12}>
    <LineChartExample />
      </Grid>
    </Grid>
  );
}