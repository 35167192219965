import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useTranslation } from "react-i18next";
import formatPrice from "utils/formatPrice";

export default function ProductsTable({ products, handleClickEdit, handleClickDelete }) {
  const { t } = useTranslation();
  const tpath = "pages.admin.products.table";

  return (
    <TableContainer>
      <Table sx={{ minWidth: 300 }} aria-label="Tabla de productos">
        <TableHead>
          <TableRow>
            <TableCell>{t(`${tpath}.headers.id`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.description`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.value`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.actions`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow
              key={product.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{product.id}</TableCell>
              <TableCell>{product.description}</TableCell>
              <TableCell>${formatPrice(product.value)}</TableCell>
              <TableCell>
                <Box display="flex" gap={{md: 1}}>
                  <IconButton
                    onClick={() => handleClickEdit(product)}
                    color="info"
                    aria-label={t(`${tpath}.actions.edit`)}
                  >
                    <EditRoundedIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleClickDelete(product)}
                    color="error"
                    aria-label={t(`${tpath}.actions.delete`)}
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
