import CreateDataContext from "./CreateDataContext";
import { login as loginAdminAPI } from "services/admin/login";
import { login as loginClientAPI } from "services/client/login";
import {
  UTOKEN,
  UTOKEN_TYPE,
  UTOKEN_TYPE_ADMIN,
  UTOKEN_TYPE_CLIENT,
} from "constants/index";

const authReducer = (state, action) => {
  switch (action.type) {
    case "login":
      return { session: action.payload };
    case "logout":
      return { session: null };
    default:
      return state;
  }
};

const login =
  (dispatch) =>
  async ({ username, password, type }) => {
    let res;

    if (type === UTOKEN_TYPE_ADMIN) {
      res = await loginAdminAPI({ username, password });
    } else if (type === UTOKEN_TYPE_CLIENT) {
      res = await loginClientAPI({ username, password });
    } else {
      throw new Error("Invalid type");
    }

    localStorage.setItem(UTOKEN, res.data.token);
    localStorage.setItem(UTOKEN_TYPE, type);

    dispatch({ type: "login", payload: { ...res.data, type } });

    return res;
  };

const logout = (dispatch) => () => {
  localStorage.removeItem(UTOKEN);
  localStorage.removeItem(UTOKEN_TYPE);

  dispatch({ type: "logout" });
};

const setup = (dispatch) => () => {
  const token = localStorage.getItem(UTOKEN);
  const type = localStorage.getItem(UTOKEN_TYPE);

  if (token && type) {
    dispatch({ type: "login", payload: { token, type } });
  }
};

export const { Context, Provider } = CreateDataContext(
  authReducer,
  {
    login,
    logout,
    setup,
  },
  {
    session: null,
  }
);
