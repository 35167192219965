import { useTranslation } from "react-i18next";

const { Typography } = require("@mui/material");
const { default: Signature } = require("components/Signature");

export default function DrawSignature({ formik }) {
  const { t } = useTranslation();
  const tpath = "pages.admin.receipts.modals.fields";
  const hasError = Boolean(
    formik.touched.receiverSignature && formik.errors.receiverSignature
  );

  return (
    <>
      <Typography color={hasError && "error"} mb={1}>
        {t(`${tpath}.receiver_signature`)}
      </Typography>
      <Signature
        onSignatureChange={(image) => {
          formik.setFieldValue("receiverSignature", image);
        }}
        error={hasError}
        helperText={hasError && formik.errors.receiverSignature}
      />
    </>
  );
}
