import { Grid, Typography } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import { useFormik } from "formik";
import useAuth from "hooks/useAuth";
import LoginSchema from "schemas/login";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { UTOKEN_TYPE_ADMIN, UTOKEN_TYPE_CLIENT } from "constants/index";

export default function LoginPage() {
  const { t } = useTranslation();
  const { login } = useAuth();
  const navigate = useNavigate();
  const {
    state: { session },
  } = useAuth();

  const formik = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      login({ ...values, type: UTOKEN_TYPE_CLIENT });
    },
  });

  const handleClickAdminLogin = () => {
    navigate("/admin/ingresar");
  };

  useEffect(() => {
    if (session?.type === UTOKEN_TYPE_CLIENT) {
      navigate("/client/remitos", { replace: true });
    } else if (session?.type === UTOKEN_TYPE_ADMIN) {
      navigate("/admin/inicio", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid spacing={4} container>
        <Grid item xs={12}>
          <Typography variant="h5">{t("pages.client.login.title")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            id="username"
            label={t("pages.client.login.fields.username")}
            formik={formik}
            inputProps={{
              autocapitalize: "none",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="password"
            label={t("pages.client.login.fields.password")}
            formik={formik}
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth>
            {t("pages.client.login.fields.submit")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleClickAdminLogin} variant="text" fullWidth>
            {t("pages.client.login.admin_login")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
