import { Grid, Typography } from "@mui/material";
import Button from "components/Button";
import Modal from "components/Modal";
import useAdmin from "hooks/useAdmin";
import { useTranslation } from "react-i18next";

export default function DeleteReceiptModal({ modalState, closeModal }) {
  const { t } = useTranslation();
  const { deleteReceipt } = useAdmin();
  const tpath = "pages.admin.receipts.modals.delete";

  const handleClickDelete = async () => {
    try {
      await deleteReceipt(modalState.data.receipt.id);
      closeModal();
    } catch (error) {}
  };

  return (
    <Modal open={modalState.open} handleClose={closeModal}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid xs={12} item>
          <Typography fontWeight="bold">{t(`${tpath}.title`)}</Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">{t(`${tpath}.message`)}</Typography>
        </Grid>
        <Grid xs={6} item>
          <Button onClick={closeModal} fullWidth>
            {t(`${tpath}.buttons.cancel`)}
          </Button>
        </Grid>
        <Grid xs={6} item>
          <Button onClick={handleClickDelete} fullWidth>
            {t(`${tpath}.buttons.delete`)}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
