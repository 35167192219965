import {
  Autocomplete,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Select";
import { RECEIPT_DELIVERED } from "constants/receipts";
import { useFormik } from "formik";
import useAdmin from "hooks/useAdmin";
import { useTranslation } from "react-i18next";
import ReceiptSchema from "schemas/receipt";
import DrawSignature from "./DrawSignature";

export function CreateDeliveredReceipt({ closeModal }) {
  const { t } = useTranslation();
  const {
    state: { clients, products },
    createReceipt,
  } = useAdmin();
  const tpath = "pages.admin.receipts.modals.create";

  const formik = useFormik({
    validationSchema: ReceiptSchema,
    initialValues: {
      status: RECEIPT_DELIVERED,
      clientId: "",
      products: [],
      receiverName: "",
      receiverSignature: "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await createReceipt(values);
        closeModal();
      } catch (error) {}
    },
  });

  const handleProductChange = (event, value) => {
    formik.setFieldValue("products", value);
  };

  const handleQuantityChange = (index, event) => {
    const { value } = event.target;
    const products = [...formik.values.products];
    products[index].quantity = value;
    formik.setFieldValue("products", products);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid xs={12} item>
          <Typography fontWeight="bold">
            {t(`${tpath}.title_delivered`)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Select
            id="clientId"
            label={t(`${tpath}.fields.client`)}
            formik={formik}
            size="small"
          >
            {clients.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.company}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={products}
            getOptionLabel={(product) => product.description}
            onChange={handleProductChange}
            renderInput={(params) => (
              <Input
                {...params}
                id="products"
                label={t(`${tpath}.fields.products`)}
                formik={formik}
                size="small"
              />
            )}
          />
        </Grid>
        {formik.values.products.map((product, index) => (
          <Grid item xs={12} key={product.id}>
            <TextField
              label={t(`${tpath}.fields.product_quantity`, {
                product: product.description,
              })}
              type="number"
              variant="outlined"
              size="small"
              value={product.quantity || ""}
              onChange={(event) => handleQuantityChange(index, event)}
              error={
                formik.touched.products &&
                formik.touched.products[index] &&
                Boolean(
                  formik.errors.products &&
                    formik.errors.products[index]?.quantity
                )
              }
              helperText={
                formik.touched.products &&
                formik.touched.products[index] &&
                formik.errors.products &&
                formik.errors.products[index]?.quantity
              }
              fullWidth
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Input
            id="receiverName"
            label={t(`${tpath}.fields.receiver_name`)}
            formik={formik}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <DrawSignature formik={formik} />
        </Grid>
        <Grid xs={6} item>
          <Button onClick={closeModal} fullWidth>
            {t(`${tpath}.buttons.cancel`)}
          </Button>
        </Grid>
        <Grid xs={6} item>
          <Button type="submit" fullWidth>
            {t(`${tpath}.buttons.save`)}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
