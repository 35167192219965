import { Autocomplete, Grid, Typography } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { useFormik } from "formik";
import useAdmin from "hooks/useAdmin";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InvoiceSchema from "schemas/invoice";
import formatDate from "utils/formatDate";

export default function CreatePendingInvoice({ modalState, closeModal, receiptsForCreateInvoice }) {
  const { t } = useTranslation();
  const { createInvoice } = useAdmin();
  const tpath = "pages.admin.invoices.modals.create";

  const formik = useFormik({
    validationSchema: InvoiceSchema,
    initialValues: {
      observation: "",
      receipts: [],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await createInvoice(values);
        closeModal();
      } catch (error) {}
    },
  });

  useEffect(() => {
    if (!modalState.open) {
      formik.resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.open])

  const handleReceiptsChange = (event, value) => {
    formik.setFieldValue("receipts", value);
  };

  return (
    <Modal open={modalState.open} handleClose={closeModal}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid xs={12} item>
            <Typography fontWeight="bold">{t(`${tpath}.title`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              id="observation"
              label={t(`${tpath}.fields.observation`)}
              formik={formik}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={receiptsForCreateInvoice}
              getOptionLabel={(receipt) =>
                `${receipt.id} - ${formatDate(receipt.updatedAt)} - ${
                  receipt.Client.company
                } - $${receipt.totalValue}`
              }
              onChange={handleReceiptsChange}
              renderInput={(params) => (
                <Input
                  {...params}
                  id="receipts"
                  label={t(`${tpath}.fields.receipts`)}
                  formik={formik}
                  size="small"
                />
              )}
            />
          </Grid>
          {formik.values.receipts.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="body2">
                Total a recibir: $
                {formik.values.receipts.reduce(
                  (accumulator, receipt) => accumulator + receipt.totalValue,
                  0
                )}
              </Typography>
            </Grid>
          )}
          <Grid xs={6} item>
            <Button onClick={closeModal} fullWidth>
              {t(`${tpath}.buttons.cancel`)}
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button type="submit" fullWidth>
              {t(`${tpath}.buttons.save`)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
}
