import { Routes, Route } from "react-router-dom";
import AdminRoutes from "pages/Admin/routes";
import NotFoundPage from "pages/NotFound";
import { Provider as AdminProvider } from "contexts/AdminContext";
import { Provider as ClientProvider } from "contexts/ClientContext";
import ClientRoutes from "pages/Client/routes";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";

export default function MyRoutes() {
  const { setup } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setup();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !isLoading && (
      <Routes>
        <Route
          path="/admin/*"
          element={
            <AdminProvider>
              <AdminRoutes />
            </AdminProvider>
          }
        />
        <Route
          path="/client/*"
          element={
            <ClientProvider>
              <ClientRoutes />
            </ClientProvider>
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    )
  );
}
