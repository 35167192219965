import {
  Modal as MuiModal, Box, Fade, IconButton, alpha, 
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: {
      xs: '400px',
      md: '540px',
    },
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.04)',
    border: 'none',
    borderRadius: '10px',
    p: { xs: 2, md: 3 },
    outline: 'none',
  },
  closeButtonContainer: {
    position: 'absolute',
    p: { xs: 1, md: 2 },
    right: 0,
    top: 0,
  },
  closeButton: {
    '& .MuiTouchRipple-child': {
      backgroundColor: 'text.primary',
    },
    '&:hover': {
      backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.1),
    },
    color: 'text.primary',
  },
};

export default function Modal({
  open,
  handleClose,
  children,
  sx,
  keepMounted,
}) {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      keepMounted={keepMounted}
    >
      <Fade in={open}>
        <Box sx={{ ...styles.container, ...sx }}>
          <Box sx={styles.closeButtonContainer}>
            <IconButton sx={styles.closeButton} onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Fade>
    </MuiModal>
  );
}
