import { Box, Grid, Paper, Typography } from "@mui/material";
import ClientsTable from "./components/ClientsTable";
import { useEffect } from "react";
import DeleteClientModal from "./components/DeleteClientModal";
import useModal from "hooks/useModal";
import useAdmin from "hooks/useAdmin";
import EditClientModal from "./components/EditClientModal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import CreateClientModal from "./components/CreateClientModal";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { sendWelcomeEmailClient } from "services/admin/clients";
import useAlerts from "hooks/useAlerts";

export default function ClientsPage() {
  const { t } = useTranslation();
  const {
    state: { clients },
    getClients,
  } = useAdmin();
  const { openSnackbar } = useAlerts();
  const [openCreateModal, closeCreateModal, createModalState] = useModal();
  const [openDeleteModal, closeDeleteModal, deleteModalState] = useModal();
  const [openEditModal, closeEditModal, editModalState] = useModal(false, {
    client: {
      username: "",
      email: "",
      company: "",
      address: "",
      phone: "",
    },
  });

  const handleClickCreate = () => {
    openCreateModal();
  };

  const handleClickEdit = (client) => {
    openEditModal({ client });
  };

  const handleClickDelete = (client) => {
    openDeleteModal({ client });
  };

  const handleClickSendWelcomeEmail = (client) => {
    sendWelcomeEmailClient(client.id).then(() => {
      openSnackbar({
        message: "Mensaje de bienvenida enviado",
        severity: "success",
      });
    });
  };

  useEffect(() => {
    if (!clients.length) {
      getClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CreateClientModal
        modalState={createModalState}
        closeModal={closeCreateModal}
      />
      <EditClientModal
        modalState={editModalState}
        closeModal={closeEditModal}
      />
      <DeleteClientModal
        modalState={deleteModalState}
        closeModal={closeDeleteModal}
      />
      <Grid p={{ xs: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} container>
        <Grid item xs={12}>
          <Paper>
            <Box
              p={{ xs: 2, md: 3 }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">
                {t("pages.admin.clients.title")}
              </Typography>
              <Button onClick={handleClickCreate}>
                {t("pages.admin.clients.buttons.create")}
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {clients.length ? (
              <ClientsTable
                clients={clients}
                handleClickEdit={handleClickEdit}
                handleClickDelete={handleClickDelete}
                handleClickSendWelcomeEmail={handleClickSendWelcomeEmail}
              />
            ) : (
              <Box p={{ xs: 2, md: 3 }} display="flex" alignItems="center">
                <WarningAmberRoundedIcon sx={{ mr: 1 }} />
                <Typography variant="body2">
                  {t("pages.admin.clients.no_clients")}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
