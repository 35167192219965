import { useState } from "react";

export default function useModal(open = false, data = null) {
  const [modalState, setModalState] = useState({ open, data });

  function openModal(data) {
    setModalState({ open: true, data });
  }

  function closeModal() {
    setModalState({ ...modalState, open: false });
  }

  return [
    openModal,
    closeModal,
    modalState,
  ];
}
