import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { useTranslation } from "react-i18next";
import formatDate from "utils/formatDate";
import formatPrice from "utils/formatPrice";

function getReceiptsTotalPrice(receipts) {
  return receipts.reduce(
    (accumulator, receipt) => accumulator + receipt.totalValue,
    0
  );
}

export default function InvoicesTable({
  invoices,
  handleClickSee,
}) {
  const { t } = useTranslation();
  const tpath = "pages.admin.invoices.table";

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="Tabla de remitos">
        <TableHead>
          <TableRow>
            <TableCell>{t(`${tpath}.headers.id`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.clients`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.observation`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.total_value`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.created_by`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.created_at`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.actions`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow
              key={invoice.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{invoice.id}</TableCell>
              <TableCell>
                {invoice.Receipts.map((receipt) => (
                  <a
                    style={{ display: "block" }}
                    key={receipt.id}
                    href={`/admin/clientes/${receipt.Client.id}`}
                    target="__blank"
                  >
                    {receipt.Client.company}
                  </a>
                ))}
              </TableCell>
              <TableCell>
                {invoice.observation
                  ? invoice.observation
                  : t(`${tpath}.no_observation`)}
              </TableCell>
              <TableCell>
                ${formatPrice(getReceiptsTotalPrice(invoice.Receipts))}
              </TableCell>
              <TableCell>{invoice.Administrator.username}</TableCell>
              <TableCell>{formatDate(invoice.createdAt)}</TableCell>
              <TableCell>
                <Box display="flex" gap={{ md: 1 }}>
                  <IconButton
                    onClick={() => handleClickSee(invoice)}
                    color="success"
                    aria-label={t(`${tpath}.actions.see`)}
                  >
                    <VisibilityRoundedIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
