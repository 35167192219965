import causaAPI from "apis/causa";

export async function getReceipts(limit, offset) {
  const response = await causaAPI.get("/receipts", {
    params: { limit, offset },
  });
  return response.data;
}

export async function getReceiptsForNewInvoice() {
  const response = await causaAPI.get("/receipts/new-invoice");
  return response.data;
}

export async function getReceipt(id) {
  const response = await causaAPI.get(`/receipt/${id}`);
  return response.data;
}

export async function getReceiptPDF(id) {
  const response = await causaAPI.get(`/receipt/${id}/pdf`);
  return response.data;
}

export async function createReceipt(data) {
  const response = await causaAPI.post("/receipt", data);
  return response.data;
}

export async function editReceipt(id, data) {
  const response = await causaAPI.put(`/receipt/${id}`, data);
  return response.data;
}

export async function deleteReceipt(id) {
  const response = await causaAPI.delete(`/receipt/${id}`);
  return response.data;
}
