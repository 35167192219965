import * as Yup from "yup";
import { RECEIPT_DELIVERED, RECEIPT_PENDING } from "constants/receipts";

const isValidBase64 = (value) => {
  if (typeof value !== "string") {
    return false;
  }

  const base64Regex = /^(data:image\/[a-zA-Z]*;base64,)[^\s]*$/;
  return base64Regex.test(value);
};

const ReceiptSchema = Yup.object({
  status: Yup.string()
    .required("El estado es requerido")
    .oneOf([RECEIPT_PENDING, RECEIPT_DELIVERED]),
  clientId: Yup.string().required("El cliente es requerido"),
  products: Yup.array()
    .of(
      Yup.object({
        id: Yup.string().required("El ID del producto es requerido"),
        quantity: Yup.number().required(
          "La cantidad del producto es requerida"
        ),
      })
    )
    .min(1, "Debe haber al menos un producto"),
  receiverName: Yup.string().when("status", {
    is: RECEIPT_DELIVERED,
    then: (schema) => schema.required("El nombre del receptor es requerido"),
  }),
  receiverSignature: Yup.string().when("status", {
    is: RECEIPT_DELIVERED,
    then: (schema) =>
      schema
        .required("La firma del receptor es requerida")
        .test("isValidBase64", "La firma no es valida", (value) => {
          return isValidBase64(value) && value !== "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
        }),
  }),
});

export default ReceiptSchema;
