import { Typography } from "@mui/material";
import { UTOKEN_TYPE_ADMIN, UTOKEN_TYPE_CLIENT } from "constants/index";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ProtectedAdminRoutes({ children }) {
  const {
    state: { session },
  } = useAuth();
  const navigate = useNavigate();
  const [hasSession, setHasSession] = useState(false);

  useEffect(() => {
    if (session?.type === UTOKEN_TYPE_ADMIN) {
      setHasSession(true);
    } else if (session?.type === UTOKEN_TYPE_CLIENT) {
      navigate("/client/remitos", { replace: true });
    } else {
      navigate("/admin/ingresar", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return <>{hasSession ? children : <Typography>Cargando...</Typography>}</>;
}
