import { createTheme } from "@mui/material";
import { esES } from "@mui/x-data-grid";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  BACKGROUND,
  TEXT,
} from "./constants/colors";

const globalTypographyStyles = {
  fontFamily: "Inter",
  color: TEXT.primary,
};

const theme = createTheme(
  {
    palette: {
      type: "light",
      primary: PRIMARY_COLOR,
      secondary: SECONDARY_COLOR,
      background: BACKGROUND,
      text: TEXT,
    },
    shape: {
      borderRadius: 10,
    },
    typography: {
      allVariants: {
        color: TEXT.primary,
      },
      fontFamily: "Inter",
    },
    /* components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& > div.MuiInputBase-sizeSmall.MuiInputBase-formControl": {
              height: "37px",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "&.MuiInputBase-sizeSmall.MuiInputBase-formControl": {
              height: "37px",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "&.MuiInputBase-sizeSmall.MuiInputBase-formControl": {
              height: "37px",
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.MuiFormLabel-root.MuiInputLabel-sizeSmall[data-shrink="false"]': {
              transform: "translate(14px, 5px) scale(1)",
              fontSize: 18
            },
          },
        },
      },
    }, */
  },
  esES
);

theme.typography.h5 = {
  ...globalTypographyStyles,
  fontSize: "1.2rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

theme.typography.h6 = {
  ...globalTypographyStyles,
  fontSize: "1.05rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.75rem",
  },
};

theme.typography.body1 = {
  ...globalTypographyStyles,
  fontSize: "0.9rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
};

theme.typography.body2 = {
  ...globalTypographyStyles,
  fontSize: "0.8rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
};

theme.typography.button = {
  ...globalTypographyStyles,
  fontSize: "0.9rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.1rem",
  },
};

export default theme;
