import {
  alpha,
  Box,
  Button,
  Typography,
  useTheme,
  Snackbar as MuiSnackbar,
} from "@mui/material";
import { cloneElement } from "react";

export default function Snackbar({
  severity = "success",
  message,
  icon,
  open,
  handleClose,
  autoHideDuration = 3000,
}) {
  const theme = useTheme();

  const severitiesColors = {
    success: {
      boxShadow: `0px 2px 2px 1px ${alpha(theme.palette.success.main, 0.15)}`,
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    error: {
      boxShadow: `0px 2px 2px 1px ${alpha(theme.palette.error.main, 0.15)}`,
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    info: {
      boxShadow: `0px 2px 2px 1px ${alpha(theme.palette.info.main, 0.15)}`,
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
  };

  const styles = {
    container: {
      boxShadow: severitiesColors[open.severity || severity].boxShadow,
      borderRadius: "10px 9px 9px 10px",
      width: { xs: "100%", sm: "400px" },
      minHeight: "40px",
      background: "#FFFFFF",
      display: "flex",
      alignItems: "stretch",
    },
    verticalBar: {
      width: "6px",
      minHeight: "40px",
      backgroundColor:
        severitiesColors[open.severity || severity].backgroundColor,
      borderRadius: "10px 0px 0px 10px",
      marginRight: "10px",
    },
    text: {
      lineHeight: 1.2,
      my: 0.5,
    },
    messageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "80%",
      mx: "5px",
      py: 1,
    },
    button: {
      fontWeight: 700,
      fontSize: "14px",
      color: severitiesColors[open.severity || severity].color,
    },
  };

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open.state}
      onClose={handleClose}
      autoHideDuration={open.autoHideDuration || autoHideDuration}
    >
      <Box sx={styles.container}>
        <Box sx={styles.verticalBar} />
        {(open.icon || icon) && cloneElement(open.icon || icon)}
        <Box sx={styles.messageContainer}>
          <Typography sx={styles.text}>{open.message || message}</Typography>
          {open.button && (
            <Button
              onClick={open.button.onClick}
              sx={styles.button}
              color={open.severity}
              variant="text"
            >
              {open.button.label}
            </Button>
          )}
        </Box>
      </Box>
    </MuiSnackbar>
  );
}
