import * as Yup from "yup";

const ClientSchema = Yup.object({
  username: Yup.string()
    .required("El usuario es requerido")
    .test(
      "username-format",
      "El usuario debe estar en minúsculas y sin espacios",
      (value) => /^[a-z]+$/.test(value)
    ),
  email: Yup.string().email("El email no es válido").required("El email es requerido"),
  company: Yup.string().required("La empresa es requerida"),
  address: Yup.string().required("La dirección es requerida"),
  phone: Yup.string().required("El teléfono es requerido"),
});

export default ClientSchema;
