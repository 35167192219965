import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import useAdmin from "hooks/useAdmin";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import CreateInvoiceModal from "./CreateInvoiceModal";
import InvoicesTable from "./InvoicesTable";
import { useNavigate } from "react-router-dom";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { getReceiptsForNewInvoice } from "services/admin/receipts";

export default function ShowAllInvoices() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { clients, products, invoices },
    getClients,
    getProducts,
    getInvoices,
  } = useAdmin();
  const [openCreateModal, closeCreateModal, createModalState] = useModal();
  const [receiptsForCreateInvoice, setReceiptsForCreateInvoice] = useState([]);

  const handleClickCreate = () => {
    openCreateModal();
  };

  const handleClickSee = (invoice) => {
    navigate(`/admin/recibos/${invoice.id}`);
  };

  const getReceipts = async () => {
    try {
      const res = await getReceiptsForNewInvoice();
      setReceiptsForCreateInvoice(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (!clients.length) {
      getClients();
    }
    if (!products.length) {
      getProducts();
    }
    if (!invoices.length) {
      getInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getReceipts();
    // eslint-disable-next-line no-undef, react-hooks/exhaustive-deps
  }, [createModalState.open]);

  return (
    <>
      <CreateInvoiceModal
        modalState={createModalState}
        closeModal={closeCreateModal}
        receiptsForCreateInvoice={receiptsForCreateInvoice}
      />
      <Grid p={{ xs: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} container>
        <Grid item xs={12}>
          <Paper>
            <Box
              p={{ xs: 2, md: 3 }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">
                {t("pages.admin.invoices.title")}
              </Typography>
              <Tooltip title="No hay ningun remito apto para crear un recibo">
                <span>
                  <Button
                    onClick={handleClickCreate}
                    disabled={!receiptsForCreateInvoice.length}
                  >
                    {t("pages.admin.invoices.buttons.create")}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {invoices.length ? (
              <InvoicesTable
                invoices={invoices}
                handleClickSee={handleClickSee}
              />
            ) : (
              <Box p={{ xs: 2, md: 3 }} display="flex" alignItems="center">
                <WarningAmberRoundedIcon sx={{ mr: 1 }} />
                <Typography variant="body2">
                  {t("pages.admin.invoices.no_invoices")}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
