import * as Yup from "yup";

const FirstPasswordSchema = Yup.object({
  password: Yup.string()
    .required("La contraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      "La contraseña debe tener al menos una mayúscula, una minúscula y un número"
    ),
  confirmPassword: Yup.string()
    .required("La confirmación de contraseña es requerida")
    .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir"),
});

export default FirstPasswordSchema;
