import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import formatPrice from "utils/formatPrice";

export default function ReceiptsTable({ receipts }) {
  const { t } = useTranslation();
  const tpath = "pages.admin.invoice.receipts.table";

  return (
    <TableContainer>
      <Table sx={{ minWidth: 300 }} aria-label="Tabla de remitos">
        <TableHead>
          <TableRow>
            <TableCell>{t(`${tpath}.headers.id`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.client`)}</TableCell>
            <TableCell align="right">{t(`${tpath}.headers.value`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {receipts.map((receipt, i) => (
            <TableRow
              key={receipt.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <a
                  href={`/admin/remitos/${receipt.id}`}
                  target="__blank"
                >
                  {receipt.id}
                </a>
              </TableCell>
              <TableCell>{receipt.Client.company}</TableCell>
              <TableCell align="right">
                ${formatPrice(receipt.totalValue)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
