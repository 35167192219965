import { Grid, Typography } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { useFormik } from "formik";
import useAdmin from "hooks/useAdmin";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ClientSchema from "schemas/client";

export default function CreateClientModal({ modalState, closeModal }) {
  const { t } = useTranslation();
  const { createClient } = useAdmin();
  const tpath = "pages.admin.clients.modals.create";

  const formik = useFormik({
    validationSchema: ClientSchema,
    initialValues: {
      username: "",
      email: "",
      company: "",
      address: "",
      phone: "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await createClient(values);
        closeModal();
      } catch (error) {}
    },
  });

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.open]);

  return (
    <Modal open={modalState.open} handleClose={closeModal}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid xs={12} item>
            <Typography fontWeight="bold">{t(`${tpath}.title`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              id="username"
              label={t(`${tpath}.fields.username`)}
              formik={formik}
              size="small"
              inputProps={{
                autocapitalize: "none",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="email"
              label={t(`${tpath}.fields.email`)}
              formik={formik}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="company"
              label={t(`${tpath}.fields.company`)}
              formik={formik}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="address"
              label={t(`${tpath}.fields.address`)}
              formik={formik}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="phone"
              label={t(`${tpath}.fields.phone`)}
              formik={formik}
              validate={(value) => value === "" || /^\d{1,10}$/.test(value)}
              size="small"
            />
          </Grid>
          <Grid xs={6} item>
            <Button onClick={closeModal} fullWidth>
              {t(`${tpath}.buttons.cancel`)}
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button type="submit" fullWidth>
              {t(`${tpath}.buttons.save`)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
}
