import {
  alpha,
  Box,
  Collapse,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useMobileBreakpoint from "hooks/useMobileBreakpoint";
import { ACCION, EXTERNO, INTERNO, LOGOUT } from "constants/menu";
import useAuth from "hooks/useAuth";

function List({ items, drawerOpen, handleDrawerToggle }) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [openCollapse, setOpenCollapse] = useState(false);
  const { logout } = useAuth();
  const isMobile = useMobileBreakpoint();
  const [whiteSpace, setWhiteSpace] = useState("nowrap");

  const handleClickMultipleItem = () => {
    setOpenCollapse((prevState) => !prevState);
  };

  const styles = {
    listItem: {
      border: "none",
      outline: "none",
      width: "100%",
      backgroundColor: "transparent",
      borderRadius: "10px",
      display: "flex",
      alignItems: "center",
      padding: 2.2,
      transition: "background-color 0.1s",
      cursor: "pointer",
      userSelect: "none",
      "&:hover, &:focus-visible": {
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      },
      "& .MuiTypography-root, & .MuiSvgIcon-root": {
        color: "text.primary",
      },
      "&[data-selected=true]": {
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          color: "secondary.main",
          fontWeight: "bold",
        },
      },
    },
    text: {
      display: "flex",
      alignItems: "center",
      ml: 1,
      whiteSpace,
      textAlign: "left",
      opacity: 0,
      transition: "opacity 0.2s",
      "&[data-is-visible=true]": {
        opacity: 1,
      },
    },
  };

  const handleClickExternalItem = (redirectTo) => {
    window?.open(redirectTo, "_blank");
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  const handleClickInternalItem = (redirectTo) => {
    navigate(redirectTo);
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  const handleActionItem = (accion) => {
    switch (accion) {
      case LOGOUT:
        logout();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    let whiteSpaceTimeout;
    if (drawerOpen) {
      whiteSpaceTimeout = setTimeout(() => {
        setWhiteSpace("break-spaces");
      }, [theme.transitions.duration.leavingScreen]);
    } else {
      setWhiteSpace("nowrap");
      clearTimeout(whiteSpaceTimeout);
    }

    return () => {
      clearTimeout(whiteSpaceTimeout);
    };
  }, [drawerOpen, theme.transitions.duration.leavingScreen]);

  return (
    <>
      <Grid spacing={2} padding={2} container>
        {items.always?.map((item) => {
          const isSelected = location.pathname === item.redirectTo;

          return (
            <Grid key={item.id} xs={12} item>
              <Box>
                <Box
                  onClick={() => {
                    switch (item.type) {
                      case INTERNO:
                        handleClickInternalItem(item.redirectTo);
                        break;
                      case EXTERNO:
                        handleClickExternalItem(item.redirectTo);
                        break;
                      case ACCION:
                        handleActionItem(item.redirectTo);
                        break;
                      default:
                        break;
                    }
                  }}
                  component="button"
                  sx={styles.listItem}
                  data-selected={isSelected}
                >
                  <>{item.icon}</>
                  <Typography
                    variant="body2"
                    data-is-visible={drawerOpen || isMobile}
                    sx={styles.text}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
        {isMobile && (
          <>
            {items.onMobile?.map((item) => {
              const isSelected = location.pathname === item.redirectTo;

              if (item.type === "external-multiple") {
                return (
                  <Grid key={item.id} xs={12} item>
                    <Box>
                      <Box
                        onClick={handleClickMultipleItem}
                        component="button"
                        sx={styles.listItem}
                      >
                        {item.icon}
                        <Typography sx={styles.text} color="primary.dark">
                          {item.text}
                          {openCollapse ? (
                            <ExpandLess sx={{ ml: 1 }} fontSize="inherit" />
                          ) : (
                            <ExpandMore sx={{ ml: 1 }} fontSize="inherit" />
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                      <Grid spacing={2} pl={3} pt={2} container>
                        {item.childrens?.map((i) => (
                          <Grid key={i.id} xs={12} item>
                            <Box>
                              <Box
                                onClick={() =>
                                  handleClickExternalItem(i.redirectTo)
                                }
                                component="button"
                                sx={styles.listItem}
                              >
                                {i.icon}
                                <Typography
                                  sx={styles.text}
                                  color="primary.dark"
                                >
                                  {i.text}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Collapse>
                  </Grid>
                );
              }

              return (
                <Grid key={item.id} xs={12} item>
                  <Box>
                    <Box
                      onClick={() => {
                        if (item.type === "external") {
                          handleClickExternalItem(item.redirectTo);
                        } else {
                          handleClickInternalItem(item.redirectTo);
                        }
                      }}
                      component="button"
                      sx={styles.listItem}
                      data-selected={isSelected}
                    >
                      <>{item.icon}</>
                      <Typography
                        sx={styles.text}
                        color={isSelected ? "white" : "primary.dark"}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
      <Grid spacing={2} px={2} pb={2} flex={1} alignItems="flex-end" container>
        {items.fixedBottom?.map((item) => {
          const isSelected = location.pathname === item.redirectTo;

          return (
            <Grid key={item.id} xs={12} item>
              <Box>
                <Box
                  component="button"
                  sx={styles.listItem}
                  data-selected={isSelected}
                  onClick={() => {
                    if (item.type === "external") {
                      handleClickExternalItem(item.redirectTo);
                    } else if (item.type === "internal") {
                      handleClickInternalItem(item.redirectTo);
                    } else {
                      handleActionItem(item.redirectTo);
                    }
                  }}
                >
                  <>{item.icon}</>

                  <Typography
                    variant="body2"
                    data-is-visible={drawerOpen || isMobile}
                    data-is-selected={isSelected}
                    sx={styles.text}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default React.memo(List);
