import { Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { getInvoices, getInvoicesCount } from "services/client/invoices";
import { Link } from "react-router-dom";
import formatPrice from "utils/formatPrice";
import formatDate from "utils/formatDate";

const columns = [
  {
    field: "id",
    headerName: "#",
    renderCell: (params) => (
      <Link to={`/client/recibos/${params.value}`}>{params.value}</Link>
    ),
    flex: 0.5,
  },
  {
    field: "observation",
    headerName: "Observacion",
    flex: 3,
  },
  {
    field: "Receipts",
    headerName: "Valor total",
    renderCell: (params) => {
      const totalValue = params.value.reduce(
        (accumulator, receipts) => accumulator + receipts.totalValue,
        0
      );
      return `$${formatPrice(totalValue)}`;
    },
    flex: 3,
  },
  {
    field: "createdAt",
    headerName: "Fecha",
    renderCell: (params) => params.value && formatDate(params.value),
    flex: 3,
  },
];

export default function ShowAllInvoices() {
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [loadingCount, setLoadingCount] = useState(0);

  useEffect(() => {
    setIsLoading(loadingCount > 0);
  }, [loadingCount]);

  useEffect(() => {
    setLoadingCount((prevCount) => prevCount + 1);
    getInvoices(
      paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize
    )
      .then((res) => {
        setRows(res.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingCount((prevCount) => prevCount - 1);
      });
  }, [paginationModel]);

  useEffect(() => {
    setLoadingCount((prevCount) => prevCount + 1);
    getInvoicesCount()
      .then((res) => {
        setRowCount(res.data.count);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingCount((prevCount) => prevCount - 1);
      });
  }, []);

  return (
    <Grid p={{ xs: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} container>
      <Grid item xs={12}>
        <Paper sx={{ p: { xs: 2, md: 3 } }}>
          <Typography fontWeight="bold">
            {t("pages.client.invoices.title")}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid
            columns={columns}
            rows={rows}
            rowCount={rowCount}
            loading={isLoading}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
