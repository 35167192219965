import { TextField } from "@mui/material";

export default function Input({
  id,
  label,
  variant = "outlined",
  formik,
  validate,
  ...props
}) {

  const handleChange = (event) => {
    const { value } = event.target;
    
    if (!validate || validate(value)) {
      formik.handleChange(event);
    }
  };

  return (
    <TextField
      id={id}
      label={label}
      variant={variant}
      value={formik.values[id]}
      onChange={handleChange}
      error={
        formik.touched[id] &&
        !Array.isArray(formik.errors[id]) &&
        Boolean(formik.errors[id])
      }
      helperText={
        formik.touched[id] &&
        !Array.isArray(formik.errors[id]) &&
        formik.errors[id]
      }
      fullWidth
      {...props}
    />
  );
}
