import axios from "axios";
import { CAUSA_API_URL, UTOKEN } from "constants/index";
import Snackbar from "components/Snackbar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "hooks/useAuth";
import useAlerts from "hooks/useAlerts";
const causaAPI = axios.create({
  baseURL: CAUSA_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

causaAPI.defaults.timeout = 5000;

causaAPI.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(UTOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

function AxiosInterceptor({ children }) {
  const { t } = useTranslation();
  const [isSet, setIsSet] = useState(false);
  const {
    openSnackbar,
    closeSnackbar,
    state: { snackbarState },
  } = useAlerts();
  const { logout } = useAuth();

  useEffect(() => {
    const resInterceptor = (response) => {
      if (response.data.status) {
        return response;
        // eslint-disable-next-line no-else-return
      } else {
        openSnackbar({
          message: response.data.message,
          severity: "warning",
        });
        return response;
      }
    };

    const errInterceptor = (error) => {
      let message;
      if (error.response) {
        const res = error.response;
        switch (res.status) {
          case 401:
            logout();
            break;
          case 400:
          case 403:
          case 404:
          case 409:
          case 422:
          case 429:
            message = res.data.message;
            break;
          case 500:
            message = t("dialogs.error.unexpected");
            break;
          default:
            message = t("dialogs.error.unexpected");
            break;
        }

        if (message) {
          openSnackbar({
            message,
            severity: "error",
          });
        }
      }

      return Promise.reject(error);
    };

    const interceptor = causaAPI.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    setIsSet(true);

    return () => causaAPI.interceptors.response.eject(interceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isSet && (
      <>
        <Snackbar open={snackbarState} handleClose={closeSnackbar} />
        {children}
      </>
    )
  );
}

export default causaAPI;
export { AxiosInterceptor as CausaAxiosInterceptor };
