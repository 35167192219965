import { Grid, Typography } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { useFormik } from "formik";
import useAdmin from "hooks/useAdmin";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DrawSignature from "./CreateReceiptModal/DrawSignature";
import ReceiptSchema from "schemas/receipt";
import { RECEIPT_DELIVERED } from "constants/receipts";

export default function EditReceiptModal({ modalState, closeModal }) {
  const { t } = useTranslation();
  const { editReceipt } = useAdmin();
  const tpath = "pages.admin.receipts.modals.edit";

  const formik = useFormik({
    validationSchema: ReceiptSchema,
    initialValues: {
      id: modalState.data?.receipt?.id,
      clientId: modalState.data?.receipt?.clientId,
      status: RECEIPT_DELIVERED,
      status_disabled: "Entregado",
      receiverName: "",
      receiverSignature: "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await editReceipt(values.id, values);
        closeModal();
      } catch (error) {}
    },
  });

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.open]);

  return (
    <Modal open={modalState.open} handleClose={closeModal}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid xs={12} item>
            <Typography fontWeight="bold">{t(`${tpath}.title`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              id="status_disabled"
              label={t(`${tpath}.fields.status`)}
              formik={formik}
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="receiverName"
              label={t(`${tpath}.fields.receiver_name`)}
              formik={formik}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <DrawSignature formik={formik} />
          </Grid>
          <Grid xs={6} item>
            <Button onClick={closeModal} fullWidth>
              {t(`${tpath}.buttons.cancel`)}
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button type="submit" fullWidth>
              {t(`${tpath}.buttons.save`)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
}
