import { BrowserRouter } from "react-router-dom";
import MyRoutes from "./routes";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { CausaAxiosInterceptor } from "apis/causa";
import { Provider as AuthProvider } from "contexts/AuthContext";
import { Provider as AlertsProvider } from "contexts/AlertsContext";
import { LicenseInfo } from '@mui/x-license-pro';
import { MUIX_KEY } from "constants";

LicenseInfo.setLicenseKey(MUIX_KEY);

function App() {
  return (
    <AuthProvider>
      <AlertsProvider>
        <CausaAxiosInterceptor>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <MyRoutes />
            </BrowserRouter>
          </ThemeProvider>
        </CausaAxiosInterceptor>
      </AlertsProvider>
    </AuthProvider>
  );
}

export default App;
