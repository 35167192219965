import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';

import { SwipeableDrawer } from "@mui/material";
import { ACCION, INTERNO, LOGOUT } from "constants/menu";
import List from "./List";

const drawerWidth = {
  desktop: 250,
  mobile: 250,
};

const drawerOptions = {
  always: [
    {
      id: 1,
      icon: <HomeRoundedIcon />,
      text: "Inicio",
      redirectTo: "/admin/inicio",
      type: INTERNO,
    },
    {
      id: 2,
      icon: <PeopleRoundedIcon />,
      text: "Clientes",
      redirectTo: "/admin/clientes",
      type: INTERNO,
    },
    {
      id: 3,
      icon: <ReceiptLongRoundedIcon />,
      text: "Remitos",
      redirectTo: "/admin/remitos",
      type: INTERNO,
    },
    {
      id: 4,
      icon: <DescriptionRoundedIcon />,
      text: "Recibos",
      redirectTo: "/admin/recibos",
      type: INTERNO,
    },
    {
      id: 5,
      icon: <ShoppingCartRoundedIcon />,
      text: "Productos",
      redirectTo: "/admin/productos",
      type: INTERNO,
    },
    {
      id: 6,
      icon: <FindInPageRoundedIcon />,
      text: "Reportes",
      redirectTo: "/admin/reportes",
      type: INTERNO,
    },
    {
      id: 7,
      icon: <ManageAccountsRoundedIcon />,
      text: "Cuenta",
      redirectTo: "/admin/cuenta",
      type: INTERNO,
    },
  ],
  onMobile: [],
  fixedBottom: [
    {
      id: 6,
      icon: <LogoutRoundedIcon />,
      text: "Cerrar sesión",
      redirectTo: LOGOUT,
      type: ACCION,
    },
  ],
};

const openedMixin = (theme) => ({
  width: drawerWidth.desktop,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("md")]: {
    width: "88px",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth.desktop,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "&  .MuiDrawer-paper": {
    border: "none",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
}));

function MiniDrawer({
  open,
  handleOpenDrawer,
  handleCloseDrawer,
  handleDrawerToggle,
  isMobile,
}) {
  return (
    <Box sx={{ display: "flex" }}>
      {isMobile ? (
        <SwipeableDrawer
          open={open}
          onClose={handleCloseDrawer}
          onOpen={handleOpenDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          disableBackdropTransition
          BackdropProps={{ invisible: true }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth.mobile,
              backdropFilter: "blur(8px)",
              boxShadow: "2px 2px rgba(0, 0, 0, 0.04)",
            },
            backgroundColor: "transparent",
          }}
        >
          <List
            items={drawerOptions}
            drawerOpen={open}
            handleDrawerToggle={handleDrawerToggle}
          />
        </SwipeableDrawer>
      ) : (
        <Drawer
          onMouseEnter={handleOpenDrawer}
          onMouseLeave={handleCloseDrawer}
          variant="permanent"
          open={open}
        >
          <List
            items={drawerOptions}
            drawerOpen={open}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Drawer>
      )}
    </Box>
  );
}

export default React.memo(MiniDrawer);
