import causaAPI from "apis/causa";

export async function getInvoices() {
  const response = await causaAPI.get("/invoices/client");
  return response.data;
}

export async function getInvoicesCount() {
  const response = await causaAPI.get(`/invoices/client/count`);
  return response.data;
}

export async function getInvoice(id) {
  const response = await causaAPI.get(`/invoice/client/${id}`);
  return response.data;
}
