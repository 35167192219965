import { getReceipts as getReceiptsAPI } from "services/client/receipts";
import { getInvoices as getInvoicesAPI } from "services/client/invoices";
import CreateDataContext from "./CreateDataContext";

const authReducer = (state, action) => {
  switch (action.type) {
    case "setReceipts":
      return { ...state, receipts: action.payload };
    case "setInvoices":
      return { ...state, invoices: action.payload };
    default:
      return state;
  }
};

const getReceipts = (dispatch) => async () => {
  const res = await getReceiptsAPI();
  dispatch({ type: "setReceipts", payload: res.data });
};

const getInvoices = (dispatch) => async () => {
  const res = await getInvoicesAPI();
  dispatch({ type: "setInvoices", payload: res.data });
};

export const { Context, Provider } = CreateDataContext(
  authReducer,
  {
    getReceipts,
    getInvoices,
  },
  {
    receiptsCount: null,
    receipts: [],
    invoices: [],
  }
);
