import causaAPI from "apis/causa";

export async function getInvoices() {
  const response = await causaAPI.get("/invoices");
  return response.data;
}

export async function getInvoice(id) {
  const response = await causaAPI.get(`/invoice/${id}`);
  return response.data;
}

export async function createInvoice(data) {
  const response = await causaAPI.post("/invoice", data);
  return response.data;
}

export async function editInvoice(id, data) {
  const response = await causaAPI.put(`/invoice/${id}`, data);
  return response.data;
}

export async function deleteInvoice(id) {
  const response = await causaAPI.delete(`/invoice/${id}`);
  return response.data;
}
