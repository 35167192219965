import { Paper } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { RECEIPT_DELIVERED } from "constants/receipts";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import formatDate from "utils/formatDate";
import formatPrice from "utils/formatPrice";

const columns = [
  {
    field: "id",
    headerName: "#",
    renderCell: (params) => (
      <Link to={`/client/remitos/${params.value}`}>{params.value}</Link>
    ),
    flex: 0.5,
  },
  {
    field: "status",
    headerName: "Estado",
    renderCell: (params) => {
      if (params.row.invoiceId) {
        return (
          <Link to={`/client/recibos/${params.row.invoiceId}`}>Pagado</Link>
        );
      } else {
        return params.value === RECEIPT_DELIVERED
          ? "Entregado pendiente de pago"
          : "Pendiente";
      }
    },
    flex: 3,
  },
  {
    field: "totalValue",
    headerName: "Valor total",
    renderCell: (params) => `$${formatPrice(params.value)}`,
    flex: 3,
  },
  {
    field: "updatedAt",
    headerName: "Fecha",
    renderCell: (params) => params.value && formatDate(params.value),
    flex: 3,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function ReportDataGrid() {
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [loadingCount, setLoadingCount] = useState(0);

  useEffect(() => {
    setIsLoading(loadingCount > 0);
  }, [loadingCount]);

  useEffect(() => {
    setLoadingCount((prevCount) => prevCount + 1);
    /* getReceipts(
      paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize
    )
      .then((res) => {
        setRows(res.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingCount((prevCount) => prevCount - 1);
      }); */
  }, [paginationModel]);

  useEffect(() => {
    setLoadingCount((prevCount) => prevCount + 1);
    /* getReceiptsCount()
      .then((res) => {
        setRowCount(res.data.count);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingCount((prevCount) => prevCount - 1);
      }); */
  }, []);

  return (
    <Paper>
      <DataGridPremium
        columns={columns}
        loading={isLoading}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={rows}
        slots={{ toolbar: CustomToolbar }}
      />
    </Paper>
  );
}
