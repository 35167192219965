import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

export default function BasicSelect({
  id,
  label,
  variant = "outlined",
  formik,
  children,
  ...props
}) {
  return (
    <FormControl
      error={formik.touched[id] && Boolean(formik.errors[id])}
      fullWidth
      {...props}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        name={id}
        label={label}
        value={formik.values[id]}
        onChange={formik.handleChange}
      >
        {children}
      </Select>
      {formik.touched[id] && formik.errors[id] && (
        <FormHelperText>{formik.errors[id]}</FormHelperText>
      )}
    </FormControl>
  );
}
