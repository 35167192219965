import * as Yup from "yup";

const LoginSchema = Yup.object({
  username: Yup.string().required("El usuario es requerido"),
  password: Yup.string()
    .required("La contraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      "La contraseña debe tener al menos una mayúscula, una minúscula y un número"
    ),
});

export default LoginSchema;
