import { Box, Grid, Paper, Typography } from "@mui/material";
import ProductsTable from "./components/ProductsTable";
import { useEffect } from "react";
import DeleteProductModal from "./components/DeleteProductModal";
import useModal from "hooks/useModal";
import useAdmin from "hooks/useAdmin";
import EditProductModal from "./components/EditProductModal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import CreateProductModal from "./components/CreateProductModal";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

export default function ProductsPage() {
  const { t } = useTranslation();
  const {
    state: { products },
    getProducts,
  } = useAdmin();
  const [openCreateModal, closeCreateModal, createModalState] = useModal();
  const [openDeleteModal, closeDeleteModal, deleteModalState] = useModal();
  const [openEditModal, closeEditModal, editModalState] = useModal(false, {
    product: {
      description: "",
      value: "",
    },
  });

  const handleClickCreate = () => {
    openCreateModal();
  };

  const handleClickEdit = (product) => {
    openEditModal({ product });
  };

  const handleClickDelete = (product) => {
    openDeleteModal({ product });
  };

  useEffect(() => {
    if (!products.length) {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CreateProductModal
        modalState={createModalState}
        closeModal={closeCreateModal}
      />
      <EditProductModal
        modalState={editModalState}
        closeModal={closeEditModal}
      />
      <DeleteProductModal
        modalState={deleteModalState}
        closeModal={closeDeleteModal}
      />
      <Grid p={{ xs: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} container>
        <Grid item xs={12}>
          <Paper>
            <Box
              p={{ xs: 2, md: 3 }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">
                {t("pages.admin.products.title")}
              </Typography>
              <Button onClick={handleClickCreate}>
                {t("pages.admin.products.buttons.create")}
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {products.length ? (
              <ProductsTable
                products={products}
                handleClickEdit={handleClickEdit}
                handleClickDelete={handleClickDelete}
              />
            ) : (
              <Box p={{ xs: 2, md: 3 }} display="flex" alignItems="center">
                <WarningAmberRoundedIcon sx={{ mr: 1 }} />
                <Typography variant="body2">
                  {t("pages.admin.products.no_products")}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
