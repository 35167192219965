import { Box, CssBaseline, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import loginBanner from "assets/images/loginBanner.webp";
import useMobileBreakpoint from "hooks/useMobileBreakpoint";
import useDeviceHeight from "hooks/useDeviceHeight";
import { useTranslation } from "react-i18next";
import CausaDarkIcon from "components/icons/CausaDark";

const styles = {
  container: {
    height: "100%",
    overflow: "auto",
  },
  imagenContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    display: "block",
    objectFit: "cover",
    width: "100%",
    height: "100vh",
  },
  outletContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    maxHeight: "100vh",
    px: { xs: 4, md: 6, lg: 12 },
    py: 15,
    position: "relative",
    alignItems: "center",
  },
  logoContainer: {
    position: "absolute",
    top: "5%",
  },
};

export default function PublicLayout() {
  const { t } = useTranslation();
  const isMobile = useMobileBreakpoint();
  const deviceHeight = useDeviceHeight();

  return (
    <>
      <CssBaseline />
      <Grid sx={{ ...styles.container, height: deviceHeight }} container>
        {!isMobile && (
          <Grid sx={styles.imagenContainer} xs={12} sm={12} md={7} item>
            <img
              src={loginBanner}
              alt={t("pages.admin.login.banner_alt")}
              style={styles.image}
            />
          </Grid>
        )}
        <Grid xs={12} sm={12} md={5} item>
          <Box sx={styles.outletContainer}>
            <Box sx={styles.logoContainer}>
              <CausaDarkIcon />
            </Box>
            <Box overflow="auto">
              <Outlet />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
