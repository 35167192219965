import { Routes, Route } from "react-router-dom";
import { HomePage, LoginPage } from "../pages";
import { PrivateLayout, PublicLayout } from "components/layouts/admin";
import ClientsPage from "../pages/Clients";
import ReceiptsPage from "../pages/Receipts";
import ProductsPage from "../pages/Products";
import InvoicesPage from "../pages/Invoices";
import NotFoundPage from "../pages/NotFound";
import AccountPage from "../pages/Account";
import ProtectedAdminRoutes from "components/ProtectedRoutes/ProtectedAdminRoutes";
import ReportsPage from "../pages/Reports";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/ingresar" element={<LoginPage />} />
      </Route>
      <Route element={<PrivateLayout />}>
        <Route
          path="/inicio"
          element={
            <ProtectedAdminRoutes>
              <HomePage />
            </ProtectedAdminRoutes>
          }
        />

        <Route
          path="/clientes"
          element={
            <ProtectedAdminRoutes>
              <ClientsPage />
            </ProtectedAdminRoutes>
          }
        />

        <Route
          path="/remitos"
          element={
            <ProtectedAdminRoutes>
              <ReceiptsPage />
            </ProtectedAdminRoutes>
          }
        />
        <Route
          path="/remitos/:id"
          element={
            <ProtectedAdminRoutes>
              <ReceiptsPage />
            </ProtectedAdminRoutes>
          }
        />

        <Route
          path="/recibos"
          element={
            <ProtectedAdminRoutes>
              <InvoicesPage />
            </ProtectedAdminRoutes>
          }
        />
        <Route
          path="/recibos/:id"
          element={
            <ProtectedAdminRoutes>
              <InvoicesPage />
            </ProtectedAdminRoutes>
          }
        />

        <Route
          path="/productos"
          element={
            <ProtectedAdminRoutes>
              <ProductsPage />
            </ProtectedAdminRoutes>
          }
        />

        <Route
          path="/reportes"
          element={
            <ProtectedAdminRoutes>
              <ReportsPage />
            </ProtectedAdminRoutes>
          }
        />

        <Route
          path="/cuenta"
          element={
            <ProtectedAdminRoutes>
              <AccountPage />
            </ProtectedAdminRoutes>
          }
        />
      </Route>
      <Route path="*" element={<NotFoundPage redirect="/admin/inicio" />} />
    </Routes>
  );
}
