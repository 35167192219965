import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import formatPrice from "utils/formatPrice";

export default function ProductsTable({ products }) {
  const { t } = useTranslation();
  const tpath = "pages.admin.receipt.products.table";

  return (
    <TableContainer>
      <Table sx={{ minWidth: 300 }} aria-label="Tabla de remitos">
        <TableHead>
          <TableRow>
            <TableCell>{t(`${tpath}.headers.id`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.description`)}</TableCell>
            <TableCell align="right">
              {t(`${tpath}.headers.quantity`)}
            </TableCell>
            <TableCell align="right">{t(`${tpath}.headers.value`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, i) => (
            <TableRow
              key={product.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{i + 1}</TableCell>
              <TableCell>{product.Product.description}</TableCell>
              <TableCell align="right">{product.quantity}</TableCell>
              <TableCell align="right">${formatPrice(product.PriceHistory.value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
