import { useState } from 'react';
import { alpha, Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import useDeviceHeight from 'hooks/useDeviceHeight';
import Drawer from './components/Drawer';
import AppBar from './components/Appbar';

const drawerWidth = {
  mobile: 250,
  desktop: 250,
};

const styles = {
  container: {
    display: 'flex',
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
    height: '100%',
    overflow: 'auto',
  },
};

export default function InicioLayout() {
  const [open, setOpen] = useState(false);
  const isMobile = useMobileBreakpoint();
  const deviceHeight = useDeviceHeight();
  let timeout;

  const handleOpenDrawer = () => {
    if (!isMobile && timeout) {
      clearTimeout(timeout);
    }
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    if (!isMobile) {
      timeout = setTimeout(() => {
        setOpen(false);
      }, 100);
    } else {
      setOpen(false);
    }
  };

  const handleDrawerToggle = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Box sx={{ ...styles.container, height: deviceHeight }}>
      <CssBaseline />
      {isMobile && <AppBar {...{ drawerWidth, handleDrawerToggle }} />}
      <Drawer
        {...{
          open,
          handleOpenDrawer,
          handleCloseDrawer,
          handleDrawerToggle,
          isMobile,
        }}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { lg: `calc(100% - ${drawerWidth.desktop * 2}px)` },
          maxWidth: '100%',
        }}
      >
        {isMobile && <Box sx={{ height: '56px' }} />}
        <Outlet />
      </Box>
    </Box>
  );
}
