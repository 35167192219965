import Modal from "components/Modal";
import { useEffect, useState } from "react";
import SelectReceiptType from "./SelectReceiptType";
import { RECEIPT_DELIVERED, RECEIPT_PENDING } from "constants/receipts";
import { CreatePendingReceipt } from "./CreatePendingReceipt";
import { CreateDeliveredReceipt } from "./CreateDeliveredReceipt";

export default function CreateReceiptModal({ modalState, closeModal }) {
  const [receiptType, setReceiptType] = useState();

  const handleClickReceiptType = (type) => {
    setReceiptType(type);
  };

  useEffect(() => {
    setReceiptType();
  }, [modalState.open]);

  return (
    <Modal open={modalState.open} handleClose={closeModal}>
      {receiptType === undefined && (
        <SelectReceiptType handleClickReceiptType={handleClickReceiptType} />
      )}
      {receiptType === RECEIPT_PENDING && (
        <CreatePendingReceipt closeModal={closeModal} />
      )}
      {receiptType === RECEIPT_DELIVERED && (
        <CreateDeliveredReceipt closeModal={closeModal} />
      )}
    </Modal>
  );
}
