export default function getProductsQuantity(products) {
  let totalQuantity = 0;

  for (const product of products) {
    const { quantity } = product;
    totalQuantity += quantity;
  }

  return totalQuantity;
}
