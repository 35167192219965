import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getReceipt } from "services/client/receipts";
import formatPrice from "utils/formatPrice";
import ProductsTable from "./ProductsTable";
import getProductsTotalPrice from "utils/getProductsTotalPrice";
import getProductsQuantity from "utils/getProductsQuantity";
import formatDate from "utils/formatDate";
import { RECEIPT_DELIVERED } from "constants/receipts";
import { useNavigate } from "react-router-dom";

const styles = {
  signatureImage: {
    height: 100,
    maxWidth: 150,
    objectFit: "contain",
    margin: "auto",
  },
};

export default function Receipt({ id }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [receipt, setReceipt] = useState({});

  useEffect(() => {
    getReceipt(id)
      .then((res) => {
        setReceipt(res.data);
      })
      .catch((err) => {
        navigate("/client/remitos");
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Grid p={{ xs: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} container>
      <Grid item xs={12}>
        <Paper>
          <Typography p={{ xs: 2, md: 3 }} fontWeight="bold">
            {t("pages.client.receipt.title", { id: receipt.id })}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ height: "100%" }}>
          <Box p={{ xs: 2, md: 3 }}>
            <Grid spacing={{ xs: 1, md: 2 }} container>
              <Grid item xs={12}>
                <Typography mb={1} fontWeight="bold">
                  {t("pages.client.receipt.data.title")}
                </Typography>
                <Divider />
              </Grid>
              {receipt.status === RECEIPT_DELIVERED && (
                <>
                  <Grid item xs={12}>
                    <Box display="flex" gap={1}>
                      <Typography variant="body2" fontWeight="bold">
                        {t("pages.client.receipt.data.receiver_name")}:
                      </Typography>
                      <Typography variant="body2">
                        {receipt.receiverName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" gap={1}>
                      <Typography variant="body2" fontWeight="bold">
                        {t("pages.client.receipt.data.receiver_signature")}:
                      </Typography>
                      <img
                        style={styles.signatureImage}
                        src={receipt.receiverSignature}
                        alt={t("pages.client.receipt.data.receiver_signature")}
                      />
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Box display="flex" gap={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.receipt.data.status.title")}:
                  </Typography>
                  <Typography variant="body2">
                    {receipt.status === "pending"
                      ? t("pages.client.receipt.data.status.receipt_pending")
                      : t("pages.client.receipt.data.status.receipt_delivered")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gap={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.receipt.data.client")}:
                  </Typography>
                  <Typography variant="body2">
                    {receipt.Client?.company}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gap={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.receipt.data.created_at")}:
                  </Typography>
                  <Typography variant="body2">
                    {receipt.createdAt && formatDate(receipt.createdAt)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gap={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.receipt.data.updated_at")}:
                  </Typography>
                  <Typography variant="body2">
                    {receipt.updatedAt &&
                      formatDate(receipt.updatedAt, {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <Box p={{ xs: 2, md: 3 }}>
            <Grid spacing={{ xs: 1, md: 2 }} container>
              <Grid item xs={12}>
                <Typography mb={1} fontWeight="bold">
                  {t("pages.client.receipt.products.title")}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {receipt.ReceiptProducts && (
                  <ProductsTable products={receipt.ReceiptProducts} />
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <Box p={{ xs: 2, md: 3 }}>
            <Grid spacing={{ xs: 1, md: 2 }} container>
              <Grid item xs={12}>
                <Typography mb={1} fontWeight="bold">
                  {t("pages.client.receipt.summary.title")}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.receipt.summary.products", {
                      quantity:
                        receipt.ReceiptProducts &&
                        getProductsQuantity(receipt.ReceiptProducts),
                    })}
                  </Typography>
                  <Typography variant="body2">
                    $
                    {receipt.ReceiptProducts &&
                      formatPrice(
                        getProductsTotalPrice(receipt.ReceiptProducts)
                      )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" fontWeight="bold">
                    {t("pages.client.receipt.summary.total")}
                  </Typography>
                  <Typography variant="body2">
                    $
                    {receipt.ReceiptProducts &&
                      formatPrice(
                        getProductsTotalPrice(receipt.ReceiptProducts)
                      )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
