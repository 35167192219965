import * as Yup from "yup";

const ProductSchema = Yup.object({
  description: Yup.string().required("La descripcion es requerida"),
  value: Yup.number()
    .typeError("Debe ser un número")
    .required("El precio es requerido"),
});

export default ProductSchema;
