import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { Card } from "@mui/material";

const periods = [
  new Date(2023, 0, 1),
  new Date(2023, 1, 1),
  new Date(2023, 2, 1),
  new Date(2023, 3, 1),
  new Date(2023, 4, 1),
  new Date(2023, 5, 1),
  new Date(2023, 6, 1),
  new Date(2023, 7, 1),
  new Date(2023, 8, 1),
  new Date(2023, 9, 1),
  new Date(2023, 10, 1),
  new Date(2023, 11, 1),
];

const brasil = [
  28129, 28294, 28619, 28336.16, 28907, 29418, 29736,
  30341, 31323, 32284, 33409, 33920,
];

const huila = [
  26189, 25792, 25790, 26349, 27277, 27861, 28472,
  29259, 30077, 30932, 31946, 32660,
];

const colombia = [
  25391, 26769, 27385, 27250, 28140, 28868, 29349,
  30186, 31129, 32087, 33367, 34260,
];

const lineChartsParams = {
  series: [
    {
      label: "Kg Brasil",
      data: brasil,
      showMark: false,
    },
    {
      label: "Kg Huila",
      data: huila,
      showMark: false,
    },
    {
      label: "Kg Colombia",
      data: colombia,
      showMark: false,
    },
  ],
  width: 1600,
  height: 400,
};

const periodsFormatter = (date) => {
  const dateFormatter = new Intl.DateTimeFormat('es-AR', {
    month: 'long',
    year: 'numeric'
  });
  return dateFormatter.format(date);
};

export default function LineChartExample() {
  return (
    <Card p={{ xs: 2, md: 3 }}>
      <LineChart
        {...lineChartsParams}
        xAxis={[
          { data: periods, scaleType: "time", valueFormatter: periodsFormatter },
        ]}
        series={lineChartsParams.series.map((s) => ({
          ...s,
        }))}
      />
    </Card>
  );
}
