export const PRIMARY_COLOR = {
  main: '#50514f',
};
export const SECONDARY_COLOR = {
  main: '#fb8500',
};

export const TEXT = {
  primary: '#333951',
  secondary: '#676767',
};

export const BACKGROUND = {
  default: '#F9F8FC',
};
