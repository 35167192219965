import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { useTranslation } from "react-i18next";

export default function ClientsTable({
  clients,
  handleClickEdit,
  handleClickDelete,
  handleClickSendWelcomeEmail,
}) {
  const { t } = useTranslation();
  const tpath = "pages.admin.clients.table";

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="Tabla de clientes">
        <TableHead>
          <TableRow>
            <TableCell>{t(`${tpath}.headers.id`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.username`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.email`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.company`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.address`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.phone`)}</TableCell>
            <TableCell>{t(`${tpath}.headers.actions`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((client) => (
            <TableRow
              key={client.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{client.id}</TableCell>
              <TableCell>{client.username}</TableCell>
              <TableCell>{client.email}</TableCell>
              <TableCell>{client.company}</TableCell>
              <TableCell>{client.address}</TableCell>
              <TableCell>{client.phone}</TableCell>
              <TableCell>
                <Box display="flex" gap={{ md: 1 }}>
                  <IconButton
                    onClick={() => handleClickEdit(client)}
                    color="info"
                    aria-label={t(`${tpath}.actions.edit`)}
                  >
                    <EditRoundedIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleClickDelete(client)}
                    color="error"
                    aria-label={t(`${tpath}.actions.delete`)}
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                  {!client.password && (
                    <IconButton
                      onClick={() => handleClickSendWelcomeEmail(client)}
                      color="success"
                      aria-label={t(`${tpath}.actions.welcome_email`)}
                    >
                      <EmailRoundedIcon />
                    </IconButton>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
