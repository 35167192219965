export default function getProductsTotalPrice(products) {
  let totalPrice = 0;

  for (const product of products) {
    const { PriceHistory, quantity } = product;
    totalPrice += PriceHistory.value * quantity;
  }

  return totalPrice;
}
