import React from "react";
import {
  AppBar as MuiAppbar,
  Toolbar,
  IconButton,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CausaDarkIcon from "components/icons/CausaDark";

function AppBar({ drawerWidth, handleDrawerToggle }) {
  return (
    <MuiAppbar
      position="fixed"
      sx={{
        width: { lg: `calc(100% - ${drawerWidth.desktop}px)` },
        ml: { lg: `${drawerWidth.desktop}px` },
        backdropFilter: "blur(5px)",
      }}
      color="transparent"
      elevation={0}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="abrir menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <CausaDarkIcon {...{ width: 130, height: 30 }} />

        <Box sx={{ width: { xs: 30, md: 40 }, height: { xs: 40, md: 48 } }} />
      </Toolbar>
    </MuiAppbar>
  );
}

export default React.memo(AppBar);
