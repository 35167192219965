import { Routes, Route } from "react-router-dom";
import NotFoundPage from "../pages/NotFound";
import { PrivateLayout, PublicLayout } from "components/layouts/client";
import FirstPassword from "../pages/FirstPassword";
import LoginPage from "../pages/Login";
import ReceiptsPage from "../pages/Receipts";
import InvoicesPage from "../pages/Invoices";
import ProtectedClientRoutes from "components/ProtectedRoutes/ProtectedClientRoutes";

export default function ClientRoutes() {
  return (
    <Routes>
      <Route element={<PrivateLayout />}>
        <Route
          path="/remitos"
          element={
            <ProtectedClientRoutes>
              <ReceiptsPage />
            </ProtectedClientRoutes>
          }
        />
        <Route
          path="/remitos/:id"
          element={
            <ProtectedClientRoutes>
              <ReceiptsPage />
            </ProtectedClientRoutes>
          }
        />

        <Route
          path="/recibos"
          element={
            <ProtectedClientRoutes>
              <InvoicesPage />
            </ProtectedClientRoutes>
          }
        />
        <Route
          path="/recibos/:id"
          element={
            <ProtectedClientRoutes>
              <InvoicesPage />
            </ProtectedClientRoutes>
          }
        />
      </Route>
      <Route element={<PublicLayout />}>
        <Route path="/ingresar" element={<LoginPage />} />
        <Route path="/first-password" element={<FirstPassword />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
