import { useParams } from "react-router-dom";
import ShowAllReceipts from "./components/ShowAllReceipts";
import Receipt from "./components/Receipt";

export default function ReceiptsPage() {
  const { id } = useParams();

  if (id) {
    return <Receipt id={id} />;
  } else {
    return <ShowAllReceipts />;
  }
}
