import { useParams } from "react-router-dom";
import ShowAllInvoices from "./components/ShowAllInvoices";
import Invoice from "./components/Invoice";

export default function InvoicesPage() {
  const { id } = useParams();

  if (id) {
    return <Invoice id={id} />;
  } else {
    return <ShowAllInvoices />;
  }
}
