import causaAPI from "apis/causa";

export async function getReceipts(limit, offset) {
  const response = await causaAPI.get("/receipts/client", {
    params: { limit, offset },
  });
  return response.data;
}

export async function getReceiptsCount() {
  const response = await causaAPI.get(`/receipts/client/count`);
  return response.data;
}

export async function getReceipt(id) {
  const response = await causaAPI.get(`/receipt/client/${id}`);
  return response.data;
}
