import { Grid, Typography } from "@mui/material";
import Button from "components/Button";
import { RECEIPT_DELIVERED, RECEIPT_PENDING } from "constants/receipts";
import { useTranslation } from "react-i18next";

export default function SelectReceiptType({ handleClickReceiptType }) {
  const { t } = useTranslation();
  const tpath = "pages.admin.receipts.modals.create";

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid xs={12} item>
        <Typography fontWeight="bold">{t(`${tpath}.title`)}</Typography>
      </Grid>
      <Grid xs={6} item>
        <Button
          onClick={() => handleClickReceiptType(RECEIPT_PENDING)}
          fullWidth
        >
          {t(`${tpath}.buttons.receipt_pending`)}
        </Button>
      </Grid>
      <Grid xs={6} item>
        <Button
          onClick={() => handleClickReceiptType(RECEIPT_DELIVERED)}
          fullWidth
        >
          {t(`${tpath}.buttons.receipt_delivered`)}
        </Button>
      </Grid>
    </Grid>
  );
}
