import { Grid, Typography } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import FirstPasswordSchema from "schemas/firstPassword";
import { firstPassword } from "services/client/firstPassword";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function FirstPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get("token");

  const formik = useFormik({
    validationSchema: FirstPasswordSchema,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      firstPassword({
        password: values.password,
        token,
      }).then((res) => {
        if (res.status) {
          navigate("/client/ingresar");
        }
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid spacing={4} container>
        <Grid item xs={12}>
          <Typography variant="h5">
            {t("pages.client.first_password.title")}
          </Typography>
          <Typography variant="subtitle">
            {t("pages.client.first_password.subtitle")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            id="password"
            label={t("pages.client.first_password.fields.password")}
            formik={formik}
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="confirmPassword"
            label={t("pages.client.first_password.fields.confirm_password")}
            formik={formik}
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth>
            {t("pages.client.first_password.fields.submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
