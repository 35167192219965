import causaAPI from "apis/causa";

export async function getClients() {
  const response = await causaAPI.get("/clients");
  return response.data;
}

export async function createClient(data) {
  const response = await causaAPI.post("/client", data);
  return response.data;
}

export async function editClient(id, data) {
  const response = await causaAPI.put(`/client/${id}`, data);
  return response.data;
}

export async function deleteClient(id) {
  const response = await causaAPI.delete(`/client/${id}`);
  return response.data;
}

export async function sendWelcomeEmailClient(id) {
  const response = await causaAPI.post(`/client/${id}/send-welcome-email`);
  return response.data;
}
