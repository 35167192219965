export default function formatDate(ISODate, options) {
  const date = new Date(ISODate);
  const dateOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    timeZone: "America/Argentina/Buenos_Aires",
    ...options,
  };

  const dateFormat = new Intl.DateTimeFormat("es-AR", dateOptions);

  return dateFormat.format(date);
}
