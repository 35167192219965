import { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Box } from "@mui/material";
import Button from "components/Button";

const styles = {
  canvasContainer: {
    border: "1px solid #c0c0c0",
    borderRadius: "10px",
    height: "200px",
    "&[data-error=true]": {
      border: (theme) => "1px solid " + theme.palette.error.main,
    },
  },
};

export default function Signature({ onSignatureChange, error, helperText }) {
  const sigCanvas = useRef(null);
  const sigCanvasContainer = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(1);

  const clear = () => {
    sigCanvas.current.clear();
    trim();
  };

  const trim = () => {
    if (sigCanvas.current) {
      const trimmedCanvas = sigCanvas.current.getTrimmedCanvas();
      const trimmedDataUrl = trimmedCanvas.toDataURL("image/png");
      onSignatureChange(trimmedDataUrl);
    }
  };

  useEffect(() => {
    const resizeHandler = () => {
      if (sigCanvasContainer.current && sigCanvasContainer.current.parentNode) {
        const parentWidth = sigCanvasContainer.current.parentNode.offsetWidth;
        setCanvasWidth(parentWidth);
      }
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <Box>
      <Box
        ref={sigCanvasContainer}
        data-error={error}
        sx={styles.canvasContainer}
      >
        <SignatureCanvas
          canvasProps={{
            width: canvasWidth,
            height: 200,
            style: { borderRadius: 10 },
          }}
          ref={sigCanvas}
          onEnd={trim}
        />
      </Box>
      <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-169bod5-MuiFormHelperText-root">
        {helperText}
      </p>
      <Box mt={1}>
        <Button color="secondary" onClick={clear} fullWidth>
          Limpiar
        </Button>
      </Box>
    </Box>
  );
}
