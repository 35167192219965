import { Grid, MenuItem, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReportDataGrid from "./components/ReportDataGrid";
import { useFormik } from "formik";
import ReportSchema from "schemas/report";
import useAdmin from "hooks/useAdmin";
import { useEffect } from "react";
import Select from "components/Select";
import {
  RECEIPT_DELIVERED,
  RECEIPT_PAID,
  RECEIPT_PENDING,
} from "constants/receipts";
import Button from "components/Button";

export default function ReportsPage() {
  const { t } = useTranslation();
  const {
    state: { clients },
    getClients,
  } = useAdmin();

  const formik = useFormik({
    validationSchema: ReportSchema,
    initialValues: {
      clientId: "",
      status: null,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        /* await createReceipt(values);
        closeModal(); */
      } catch (error) {}
    },
  });

  useEffect(() => {
    if (!clients.length) {
      getClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid p={{ xs: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} container>
      <Grid item xs={12}>
        <Paper sx={{ p: { xs: 2, md: 3 } }}>
          <Typography fontWeight="bold">
            {t("pages.admin.reports.title")}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: { xs: 2, md: 3 } }}>
          <Grid spacing={{ xs: 2, md: 3 }} container>
            <Grid item xs={3}>
              <Select
                id="clientId"
                label={t("pages.admin.reports.fields.client")}
                formik={formik}
                size="small"
                fullWidth
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.company}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Select
                id="status"
                label={t("pages.admin.reports.fields.status")}
                formik={formik}
                size="small"
                fullWidth
              >
                <MenuItem value={RECEIPT_PENDING}>Pendientes</MenuItem>
                <MenuItem value={RECEIPT_DELIVERED}>Entregados</MenuItem>
                <MenuItem value={RECEIPT_PAID}>Pagados</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Button>Buscar</Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ReportDataGrid />
      </Grid>
    </Grid>
  );
}
