import { RECEIPT_DELIVERED, RECEIPT_PAID, RECEIPT_PENDING } from "constants/receipts";
import * as Yup from "yup";

const ReportSchema = Yup.object({
  clientId: Yup.string().required("El cliente es requerido"),
  status: Yup.string()
    .required("El estado es requerido")
    .oneOf([RECEIPT_PENDING, RECEIPT_DELIVERED, RECEIPT_PAID]),
});

export default ReportSchema;
